import { AxiosPromise } from 'axios'
import { TEmployeeInviteParams } from '../../../types/models/employee/index'
import client from '../../client/auth'
import { TResponse } from '@novafuturltd/shared'

export interface TInvitesCount {
  accepted: number
  deleted: number
  pending: number
  refused: number
}

export interface TInviteResponse {
  invite: {
    created_at: string
    updated_at: string
    invites: TEmployeeInviteParams[]
  }
}

export const getInvite = (
  token: string,
): AxiosPromise<TResponse<TInviteResponse>> =>
  client.get(`user/invite/${token}`)

export const getInvites = (): AxiosPromise<TResponse<TInvitesCount>> =>
  client.get('resources/invitations/count-by-status')

// is the body missing from this request?
export const invite = (
  token: string,
): AxiosPromise<TResponse<TInviteResponse>> =>
  client.post(`user/invite/${token}`, {})

// is the token required for this request?
export const removeInvite = (
  token: string,
): AxiosPromise<TResponse<TInviteResponse>> =>
  client.delete(`/user/panding/invite`, { invite_token: token })
