import { Button, IconButton, Tooltip } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import React, { useContext } from 'react'
import { DashboardContext } from '../../containers/Dashboard/context'
import { useStyles } from './styles'
import { Can, useWindowSize } from '@novafuturltd/shared'

export interface Props {
  anchorEl: any
  handleOpenMenu: (event: any) => void
  handleCloseMenu: () => void
  handleSelect: (type: string) => () => void
  handleEditDashboard: () => void
  draggable: boolean
}

const EditDashboardOptions: React.FC<Props> = ({
  handleSelect,
  handleEditDashboard,
  draggable,
}) => {
  const { saveLayout } = useContext(DashboardContext)
  const sizes: { width: number; height: number } = useWindowSize({
    throttleMs: 10,
  })
  const classes = useStyles({ draggable, size: sizes })

  const onClickEditDashboard = () => {
    if (draggable) {
      try {
        saveLayout()
      } catch (err) {}
    }
    handleEditDashboard()
  }

  return (
    <Can
      perform={['userpref_service.links.create']}
      yes={() => (
        <div>
          <Tooltip
            title={
              draggable ? 'Save Dashboard Layout' : 'Edit Dashboard Layout'
            }
          >
            <IconButton
              onClick={onClickEditDashboard}
              size="large"
              className={classes.addWidgetIcon}
            >
              {draggable ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          </Tooltip>
          <Button
            variant="outlined"
            data-testid="add-widget-button"
            color="primary"
            startIcon={<AddCircleOutlineIcon color="primary" />}
            onClick={handleSelect('customLink')}
            className={classes.addWidgetButton}
          >
            Add Widget
          </Button>
        </div>
      )}
    />
  )
}

export default EditDashboardOptions
