import qs from 'qs'
import { services } from '../../config/index'
import { THeaders, TQueryParams } from '@novafuturltd/shared'
import client from './index'

export default {
  get: (url: string, params: TQueryParams = {}, headers: THeaders = {}) =>
    client({
      method: 'GET',
      url,
      baseURL: `${services.nHub}`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  post: (url: string, data: {} = {}, headers: THeaders = {}) =>
    client({
      method: 'POST',
      url,
      baseURL: `${services.nHub}`,
      data,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  patch: (url: string, data: {} = {}, headers: THeaders = {}) =>
    client({
      method: 'PATCH',
      url,
      baseURL: `${services.nHub}`,
      data,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  put: (url: string, data: {} = {}, headers: THeaders = {}) =>
    client({
      method: 'PUT',
      url,
      baseURL: `${services.nHub}`,
      data,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  delete: (url: string, params: TQueryParams = {}, headers: THeaders = {}) =>
    client({
      method: 'DELETE',
      url,
      baseURL: `${services.nHub}`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
}
