import { useOAuth } from '@novafuturltd/core'
import { useNotifications } from '@novafuturltd/shared'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { create as createCommunication } from '../api/nHub/marketing'
import { getApplicationData } from '../containers/CampaignMngt/Communication/utils'
import { TFilters } from '../modules/customer-overview/components/CustomersOverviewV3/types'

export const useCreateCampaign = () => {
  const { organizationId: organisationId } = useOAuth()
  const intl = useIntl()
  const { notify } = useNotifications()
  const [loading, setLoading] = useState(false)

  const createNewCommunication = async (
    type: string,
    storedAt?: string,
    description?: string,
    segmentId?: string,
    status?: string,
    audience?: {
      selectedColumns: string[]
      selectedFilters: TFilters
    },
    startDate?: string,
  ) => {
    setLoading(true)
    try {
      const applications = await getApplicationData(organisationId)
      let applicationData = applications && applications.shift()
      // very bad code just temporary for select just nigeria brand
      if (applications && applications.length > 1) {
        const found = applications.find((app: any) => app.name === 'Bet9ja')
        if (found) {
          applicationData = found
        }
      }
      if (applicationData) {
        const el =
          applicationData.config &&
          applicationData.config.find((c: any) => c.name === 'brand') // before new SF we saved info about brand
        const brand = el?.value
        // @ts-ignore
        const response = await createCommunication({
          brand: brand || 'UK',
          // title: 'Untitled',
          type,
          description: description,
          stored_at: storedAt,
          segment_id: segmentId,
          status: status,
          filters: audience?.selectedFilters,
          columns: audience?.selectedColumns,
          start_date: startDate,
          application: {
            id: applicationData.id,
            code: applicationData.appId,
            name: applicationData.name,
            config: applicationData.config
              ? applicationData.config.reduce((acc: any, value: any) => {
                  return { ...acc, [value.name]: value.value }
                }, {})
              : {},
          },
        })
        setLoading(false)
        return response.data.data
      }
    } catch (err) {
      notify.error(
        intl.formatMessage({
          id: 'notification.error.saveCommunication',
        }),
      )
      setLoading(false)
      return false
    }
  }

  const handleCreateCommunication = async (
    type: string,
    storedAt?: string,
    segmentId?: string,
    description?: string,
    status?: string,
    audience?: {
      selectedColumns: string[]
      selectedFilters: TFilters
    },
    startDate?: string,
  ) => {
    const response = await createNewCommunication(
      type,
      storedAt,
      description,
      segmentId,
      status,
      audience,
      startDate,
    )
    if (response) {
      setLoading(false)
      return response.communication_id
    }
    return false
  }

  return { handleCreateCommunication, loading }
}
