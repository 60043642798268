
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import { useNotifications } from '@novafuturltd/shared'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from '../containers/App'
import { DashboardProvider } from '../containers/Dashboard/context'
import Page from '../hocs/securedPage'

export default Page(() => {
  const { Notification, notify } = useNotifications()
  const queryClient = new QueryClient()
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <DashboardProvider notify={notify} page="dashboard">
          <App data-perform="*" />
        </DashboardProvider>
      </QueryClientProvider>
      <Notification />
    </>
  )
})
