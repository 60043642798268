export const EMAIL = 1
export const SMS = 2
export const POPUP = 3
export const NOTIFICATION = 4
export const OPERA_MINI_SPEED_DIAL = 6
export const MOBILE_PUSH_NOTIFICATION = 7
export const templateTypeMap = {
  [EMAIL]: 'Email',
  [SMS]: 'Sms',
  [POPUP]: 'In-App',
  [NOTIFICATION]: 'In-App',
  [OPERA_MINI_SPEED_DIAL]: 'Opera Mini Speed Dial',
  [MOBILE_PUSH_NOTIFICATION]: 'Webpush Mobile Notification',
}

export enum ApiTemplateTypeEnum {
  EMAIL = 1,
  SMS = 2,
  POPUP = 3,
  NOTIFICATION = 4,
  OPERA_MINI_SPEED_DIAL = 6,
  MOBILE_PUSH_NOTIFICATION = 7,
}
