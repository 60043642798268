/* eslint-disable no-plusplus */
import { THeaders, TQueryParams } from '@novafuturltd/shared'
import qs from 'qs'
import { k8BaseUrl, services } from '../../config/index'
import client from './index'

export default {
  get: (url: string, params: TQueryParams = {}, headers: THeaders = {}) =>
    client({
      method: 'GET',
      url,
      baseURL: `${k8BaseUrl}${services.marketing}`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  post: (
    url: string,
    data: {} = {},
    headers: THeaders = {},
    onUploadProgress?: () => void,
  ) =>
    client({
      method: 'POST',
      url,
      baseURL: `${k8BaseUrl}${services.marketing}`,
      data,
      headers,
      onUploadProgress,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  patch: (url: string, data: {} = {}, headers: THeaders = {}) =>
    client({
      method: 'PATCH',
      url,
      baseURL: `${k8BaseUrl}${services.marketing}`,
      data,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  put: (url: string, data: {} = {}, headers: THeaders = {}) =>
    client({
      method: 'PUT',
      url,
      baseURL: `${k8BaseUrl}${services.marketing}`,
      data,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
  delete: (url: string, params: TQueryParams = {}, headers: THeaders = {}) =>
    client({
      method: 'DELETE',
      url,
      baseURL: `${k8BaseUrl}${services.marketing}`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    }),
}
