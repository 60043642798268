/* eslint-disable no-plusplus */
import { AxiosPromise } from 'axios'
import { CommunicationTypeEnum } from '../../../containers/CampaignMngt/types'
// import { TCampaign } from '../../../types/models/communication'
import { TQueryParams, TResponse } from '@novafuturltd/shared'
import { TTemplate } from '../../../types/models/communication/template'
import client from '../../client/marketing'

interface TFilters {
  [key: string]: { [key: string]: string }
}
export interface TCampaign {
  active: boolean
  description: string
  application: any
  bonus: TCampaignBonus | undefined
  brand: string
  communication_id: string
  created_at: Date | string
  files: TCampaignCSV[]
  sent_at: Date | null
  state: string
  templates: TCampaignTemplate[]
  tags: TTag[]
  title: string
  type: CommunicationTypeEnum | string
  audience_login_type: number | null
  updated_at: Date | string
  start_date: Date | string | null
  campaign: {
    campaign_id: string
    description: string
    end_date: string | null
    start_date: string | null
    title: string
  }
  segment_id?: string
}

export interface TAudienceQuery {
  page_size: number
  page: number
  sorting: string[]
  bonus: string | object
}

export interface TValues {
  factor: string
  min: string
  max: string
  base_column: string
  amount: string
}

export interface TCampaignBonus {
  bonus_id: string
  type: string
  title: string
  description: string
  total_cost: number
  selection: {
    value: string
  }
  promo_id: string
  promotion?: string
  integrationType?: string
  duration: number
  bonusConfig: {
    factor: number
    min_bonus: number
    max_bonus: number
    base_column: string
  }
  isFixedAmount: boolean
  bonusAmount: number
  enrolling_event?: string
}

export interface TCampaignCSV {
  audience_id: string | null
  segment_id: string | null
  file_id: string
  file_name: string
  num_customers: number
  size: number
  status: string
  type: string
  path?: string
}

export enum BroadcastTypeEnum { // these 3 types are only used within Broadcast campaign - Notification + Opera will always be ALL however, Popup can be changed.
  ALL = 1,
  GUESTS = 2,
  LOGGED_IN = 3,
}

export interface TCampaignTemplate {
  broadcast_type: string | number | readonly string[] | undefined
  n_hub_template_id: string
  params: {
    display_location_urls: string[]
    visibility_time_range: string
    from_email: string
    reply_to_email: string
    from_name: string
    subject?: string | null
    template_content?: string
    expiration?: number | string
  }
  template_id: string
  delivery_stop: boolean
  title: string
  type: number
  use_policy?: boolean
  logs?: Array<any> // TODO: define type
}

export interface TUpdateCampaignBody {
  brand?: string
  title?: string
  status?: string
  audience_login_type?: number
  start_date?: string
  active_schedule_time?: string
  // csvPath: string
}

export interface TCreateCommunicationBody {
  status?: string
  application: any
  brand?: string
  title?: string
  stored_at?: string
  description?: string
  segment_id?: string
  type: string
  filters?: TFilters
  columns?: string[]
  start_date?: string | undefined
}

export interface TAddFileBody {
  file: FormData
}

export interface TUploadingFileResponse {
  communicationId: string
  state: string
  numCustomers: number
}

export interface TSendCommunicationPollingResponse {
  communicationId: string
  state: string
  generalSentPercent: number
  totalSentByTemplates: any
}

export interface TDuplicateCampaignId {
  id: string
}

export interface TDuplicateCampaignBody {
  title?: string
  description?: string
}

export interface TTag {
  tag_id: string
  name: string
}

export const list = (
  params: TQueryParams,
): AxiosPromise<TResponse<TCampaign[]>> => client.get('/communications', params)

export const get = (id: string): AxiosPromise<TResponse<TCampaign>> =>
  client.get(`/communications/${id}`)

export const updateCampaignField = (
  id: string,
  body: TUpdateCampaignBody,
): AxiosPromise<TResponse> => client.patch(`/communications/${id}`, body)

export const updateCampaign = (
  id: string,
  body: TUpdateCampaignBody,
): AxiosPromise<TResponse> => client.put(`/communications/${id}`, body)

export const create = (
  body: TCreateCommunicationBody,
): AxiosPromise<TResponse> => client.post(`/communications`, body)

export const cancelScheduledCampaign = (id: string): AxiosPromise<TResponse> =>
  client.put(`/communications/${id}/unschedule`)

export const remove = (id: string): AxiosPromise<TResponse> =>
  client.delete(`/communications/${id}`)

export const addCampaignTemplate = (
  id: string,
  body: TTemplate,
): AxiosPromise<TResponse> =>
  client.post(`/communications/${id}/template`, body)

export const updateCommunicationTemplateField = (
  templateId: string,
  body: any,
): AxiosPromise<TResponse> =>
  client.patch(`/templates/${templateId}`, { ...body })

export const updateCommunicationTemplate = (
  id: string,
  body: any,
): AxiosPromise<TResponse> =>
  client.patch(`/templates/${id}/params`, { ...body })

export const removeCommunicationTemplate = (
  tempId: string,
): AxiosPromise<TResponse> => client.delete(`/templates/${tempId}`)

export const addFile = (
  id: string,
  params: FormData,
  onUpload?: any,
): AxiosPromise<TResponse> =>
  client.post(`/communications/${id}/file`, params, {}, onUpload)

export const removeFile = (id: string): AxiosPromise<TResponse> =>
  client.delete(`/files/${id}`)

export const getFileCSV = (
  id: any,
  params: TQueryParams,
): AxiosPromise<TResponse> => client.get(`/files/${id}/csv`, params)

export const uploadFilePolling = (
  id: string,
): AxiosPromise<TResponse<TUploadingFileResponse>> =>
  client.get(`/longpolling/communication/${id}/upload-file`)

export const sendCommunication = (id: string): AxiosPromise<TResponse> =>
  client.get(`/communications/${id}/send`)

export const sendCommunicationPolling = (
  id: string,
): AxiosPromise<TResponse<TSendCommunicationPollingResponse>> =>
  client.get(`communications/${id}/send/status`)

export const sendCommunicationFailed = (id: string) =>
  client.get(`/communication/${id}/sending-failures`)

export const validateCommunication = (id: string) =>
  client.get(`/communications/${id}/validate`)

export const resendCommunication = (id: string) =>
  client.get(`/communications/${id}/resend`)

export const cancelSendCommunication = (id: string) =>
  client.get(`/communications/${id}/cancel`)

export const addDuplicateCampaign = (
  id: TDuplicateCampaignId,
  body: TDuplicateCampaignBody,
) => client.post(`/communications/${id}/duplicate`, body)
export const updateTemplatePolicy = (
  id: string,
  body: { use_policy: boolean },
): any => client.patch(`/templates/${id}`, { ...body })

export const stopResumePopupDelivery = (
  id: string,
  body: { delivery_stop: boolean },
) => client.patch(`/templates/${id}`, { ...body })

export const getCalendar = (params: {
  startDate: string
  endDate: string
  type: string
}) => client.get('/communications/calendar', params)

export const removeAllFiles = (id: string): AxiosPromise<TResponse> =>
  client.delete(`/communications/${id}/files`)

export const getAllTags = (): AxiosPromise<TResponse<TTag[]>> =>
  client.get('/tags')

export const createTag = (body: {
  name: string
}): AxiosPromise<TResponse<TTag>> => client.post('/tags', body)

export const addTagToComm = (
  communicationId: string,
  body: { name: string; tag_id: string },
): AxiosPromise<TResponse<TTag>> =>
  client.post(`/communications/${communicationId}/tag`, body)

export const removeTagFromComm = (
  communicationId: string,
  // tslint:disable-next-line: variable-name
  tag_id: string,
): AxiosPromise<TResponse<void>> =>
  client.delete(`/communications/${communicationId}/tag`, { tag_id })
