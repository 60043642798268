import { AxiosResponse } from 'axios'
import { Dispatch, SetStateAction, useState } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { TInvitesCount } from '../api/auth/invites'
import { getInvites } from '../api/auth/invites'

interface Props {
  initEnabled?: boolean
}

type TData = AxiosResponse<TInvitesCount>

interface TError {} // TODO @goughjo03 find out what this error type is

export type UseInvitesQuery = UseQueryResult<TData, TError> & {
  enabled: boolean
  setEnabled: Dispatch<SetStateAction<boolean>>
}

const useInvitesQuery = ({ initEnabled = true }: Props): UseInvitesQuery => {
  const [enabled, setEnabled] = useState<boolean>(initEnabled)
  const query = useQuery<{}, TError, TData>(
    ['invitesHook', enabled],
    () => getInvites(),
    {
      enabled,
    },
  )
  return {
    ...query,
    enabled,
    setEnabled,
  }
}

export default useInvitesQuery
