import { getByOrganizationId } from '../../../api/nHub/application/index'

import { EMAIL } from '../../../constants/TemplateTypeConstants'

export const getApplicationData = async organisationId => {
  if (organisationId)
    try {
      const response = await getByOrganizationId(organisationId)
      return response.data
    } catch (err) {
      throw new Error(err)
    }
}

export const createTemplateRequestParams = template => {
  let requestParams = {
    n_hub_template_id: template.n_hub_template_id || template.id,
    type: template.type,
    title: template.title,
    params: template.params || {},
  }

  if (template.type === EMAIL)
    requestParams = { ...requestParams, subject: template.params.subject }
  return requestParams
}

export const copyFromEmailIfReplyToIsEmpty = templates =>
  templates.map(template => {
    if (template.type === EMAIL && !template.params.replyTo.email) {
      return {
        ...template,
        params: {
          ...template.params,
          replyTo: { email: template.params.from.email },
        },
      }
    }
    return template
  })
