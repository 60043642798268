import { PageHeader } from '@novafuturltd/shared'
import { Heading } from '@novafuturltd/shared'
import React, { useState } from 'react'
import CustomLinkModal from '../../components/Dashboard/CustomLinkModal'
import EditDashboardOptions from '../../components/Dashboard/EditDashboardOptions'
import WidgetCollection from '../../components/Dashboard/WidgetCollection'
import { useOAuth } from '@novafuturltd/core'

const Dashboard: React.FC<any> = () => {
  const { profile } = useOAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState({
    customLinkModal: false,
  })
  const [draggable, setDraggable] = useState<boolean>(false)

  const handleEditDashboard = () => setDraggable(!draggable)

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSelect = (type: string) => () => {
    if (type === 'customLink') {
      setOpen({ ...open, customLinkModal: true })
    }
    handleCloseMenu()
  }

  const handleCloseModal = (modal: string) => () =>
    setOpen({ ...open, [modal]: false })

  return (
    <>
      <PageHeader
        style={{
          margin: '0px',
          alignItems: 'center',
          padding: '0 0 20px 0',
          flexWrap: 'wrap',
        }}
      >
        <Heading variant="h1">
          Welcome {profile?.firstName} to your dashboard
        </Heading>
        <EditDashboardOptions
          anchorEl={anchorEl}
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          handleSelect={handleSelect}
          handleEditDashboard={handleEditDashboard}
          draggable={draggable}
        />
      </PageHeader>
      {/* <Heading variant="body1">You last logged in on </Heading> */}
      <WidgetCollection draggable={draggable} />
      <CustomLinkModal
        open={open.customLinkModal}
        handleClose={handleCloseModal('customLinkModal')}
      />
    </>
  )
}
export default Dashboard
