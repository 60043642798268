import React from 'react'

interface Props {
  height?: string
  width?: string
  className?: string
}

export const SegmentIcon: React.FC<Props> = ({
  height,
  width,
  className,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : '24'}
    height={height ? height : '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.1028 4.7274C13.2108 4.6724 14.3478 4.8244 15.4558 5.2154C19.8628 6.7684 22.1308 11.4744 20.5218 15.7284C18.9128 19.9814 14.0348 22.1704 9.6278 20.6164C5.2208 19.0644 2.9528 14.3574 4.5618 10.1044C4.6108 9.9754 4.6718 9.8544 4.7268 9.7294L13.8268 14.1634L12.1028 4.7274ZM9.571 2L11.149 10.633L3 6.662C3.634 5.441 4.56 4.383 5.701 3.574C6.842 2.764 8.168 2.225 9.571 2Z"
      fill="#797979"
      className={className}
    />
  </svg>
)

export const SmileyIcon: React.FC<Props> = ({
  height,
  width,
  className,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : '24'}
    height={height ? height : '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM17 9.5C17 10.33 16.33 11 15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8C16.33 8 17 8.67 17 9.5ZM8.5 11C9.33 11 10 10.33 10 9.5C10 8.67 9.33 8 8.5 8C7.67 8 7 8.67 7 9.5C7 10.33 7.67 11 8.5 11ZM17.11 14C16.31 16.04 14.33 17.5 12 17.5C9.67 17.5 7.69 16.04 6.89 14H17.11Z"
      fill="#797979"
      className={className}
    />
  </svg>
)

export const UploadIcon: React.FC<Props> = ({
  height,
  width,
  className,
  ...props
}: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : '24'}
    height={height ? height : '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 10V16H9V10H5L12 3L19 10H15ZM19 20V18H5V20H19Z"
      fill="#797979"
      className={className}
    />
  </svg>
)
