// import { AxiosPromise } from 'axios'
import { TUser } from '@novafuturltd/core'
import client from '../../client/auth'
// import { TResponse } from '../../types/common'

export interface TOrgEmployeesResponse {
  employees: TUser[]
}

// export default (): AxiosPromise<TResponse<TOrgEmployeesResponse>> =>

const get = () => client.get(`resources/users`)

export default get
