import { AxiosPromise } from 'axios'
import { TApplication } from '../../../types/models/application'
import client from '../../client/nHub'

export const getByOrganizationId = (
  organizationId: string,
): AxiosPromise<TApplication[]> =>
  client.get(`v3/application/getByOrganizationId/${organizationId}`)

export const get = (id: string): AxiosPromise<TApplication> =>
  client.get(`v3/application/get/${id}`)
