import LaunchIcon from '@mui/icons-material/Launch'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import {
  BroadcastIcon,
  numberWithCommas,
  SupportIcon,
  useNotifications,
  Widget,
} from '@novafuturltd/shared'
import Router from 'next/router'
import React, { useContext, useEffect } from 'react'
import { UploadIcon } from '../../components/uielements/icons/SelectAudienceIcons'
import { DashboardContext, IUserLink } from '../../containers/Dashboard/context'
import { useStyles } from './styles'

import { useAppContext } from '@novafuturltd/core'
import SimulateIcon from '../uielements/icons/SimulateSidebarIcon'

import { useOAuth } from '@novafuturltd/core'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useIntl } from 'react-intl'
import { CommunicationAudienceTypeEnum } from '../../constants/communicationAudienceEnum'
import { useMixPanel } from '../../contexts/mixpanel'
import { useCreateCampaign } from '../../hooks/useCreateCampaign'
import useInvitesQuery from '../../hooks/useInvitesQuery'
import useUsersQuery from '../../hooks/useUsersQuery'

const ReactGridLayout = WidthProvider(Responsive)

interface Props {
  draggable: boolean
}

const WidgetCollection: React.FC<Props> = ({ draggable }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles({})
  const { permissions } = useOAuth()
  const {
    data,
    deleteUserLink,
    layouts,
    handleLayoutChange,
    simulateReportsStats,
    simulateReportsStatsLoading,
  } = useContext(DashboardContext)
  const {
    data: usersData,
    setEnabled: setUsersQueryEnabled,
    isLoading: userDataIsLoading,
  } = useUsersQuery({
    initEnabled: false,
  })
  const { openDrawer } = useAppContext()
  const mxp = useMixPanel()
  const { Notification, notify } = useNotifications()
  const { handleCreateCommunication } = useCreateCampaign()

  const getUsers = () => setUsersQueryEnabled(true)
  const {
    data: invitesData,
    isLoading: invitesLoading,
    setEnabled: setInvitesQueryEnabled,
  } = useInvitesQuery({ initEnabled: false })

  const getInvites = () => setInvitesQueryEnabled(true)

  const createCampaign = (type: string) => {
    mxp.track('Create Campaign Dashboard Shortcut', { type })
    return handleCreateCommunication(type)
  }

  const handleClickToNewCampaign = (type: string) => async () => {
    mxp.track('User Clicks Dashboard Widget', {
      id: `create-campaign-${type}`,
      window: 'existing',
    })
    try {
      const commId = await createCampaign(type)
      if (commId) {
        return Router.push(
          `/campaign-mgmt/communications/edit/[id]`,
          `/campaign-mgmt/communications/edit/${commId}?new=true`,
        )
      }
    } catch (err) {
      return notify.error('There was an error creating a communication.')
    }
  }

  const handleOpenLink = (
    url: string,
    newWindow: boolean,
    id: string,
  ) => () => {
    mxp.track('User Clicks Dashboard Widget', {
      id,
      window: newWindow ? 'new' : 'existing',
    })
    if (newWindow) {
      return window.open(url, '_blank')
    }
    return window.open(url, '_self')
  }

  const handleDeleteLink = (id: string) => async () => {
    try {
      deleteUserLink(id)
    } catch (err) {
      notify.error('Unable to delete link')
    }
  }

  const renderCustomLinks = () => {
    return data?.map((widget: IUserLink | any) => {
      // @ts-ignore
      const widgetPosition = layouts?.lg.find(
        (element: any) => element.i === widget.link_id,
      )
      const x = widgetPosition?.x ? widgetPosition?.x : 0
      const y = widgetPosition?.y ? widgetPosition?.y : 0
      return (
        <Widget
          width="320px"
          mobileWidth="100%"
          bgColor={widget.color}
          name={widget.display_name}
          description={widget.description}
          icon={<LaunchIcon fontSize="large" className={classes.icon} />}
          deletable={true}
          key={widget.link_id}
          onClick={handleOpenLink(
            widget.url,
            widget.new_window,
            widget.link_id,
          )}
          onDeleteWidget={handleDeleteLink(widget.link_id)}
          isDraggable={draggable}
          permissions={{
            required: true,
            allPermissions: permissions,
            requiredPermissions: 'userpref_service.links.list',
          }}
          data-grid={{ x, y, w: 4, h: 3 }}
        />
      )
    })
  }

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  }, [openDrawer])

  return (
    <div className={classes.widgetCollection}>
      {layouts && (
        <ReactGridLayout
          layouts={layouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768 }}
          cols={{ lg: 16, md: 12, sm: 4 }}
          width={1200}
          rowHeight={30}
          margin={[10, 10]}
          containerPadding={[10, 10]}
          className="layout"
          isDraggable={draggable}
          isResizable={false}
          onLayoutChange={handleLayoutChange}
          style={{ width: '100%' }}
          autoSize={true}
        >
          <Widget
            bgColor="ST050"
            name={invitesData?.data.pending ? invitesData?.data.pending : ''}
            description={formatMessage({
              id: invitesData?.data.pending
                ? 'dashboard.widget.description.total_hub_pending_users'
                : 'dashboard.widget.description.total_hub_pending_users.unavailable',
            })}
            icon={<PeopleAltIcon fontSize="large" className={classes.icon} />}
            deletable={false}
            loading={invitesLoading}
            onClick={() => {}}
            isClickable={false}
            callDataFunction={getInvites}
            dataTest="invites-widget"
            key="invites-widget"
            permissions={{
              required: true,
              allPermissions: permissions,
              requiredPermissions: 'nova_auth.invitation_management.list',
            }}
            data-grid={{ x: 0, y: 0, w: 4, h: 3 }}
          />
          <Widget
            dataTest="users-widget"
            bgColor="SB050"
            name={usersData?.data.length}
            description={formatMessage({
              id: usersData?.data
                ? 'dashboard.widget.description.total_users'
                : 'dashboard.widget.description.total_users.unavailable',
            })}
            icon={<PeopleAltIcon fontSize="large" className={classes.icon} />}
            deletable={false}
            loading={userDataIsLoading}
            onClick={() => {}}
            isClickable={false}
            callDataFunction={getUsers}
            key="users-widget"
            permissions={{
              required: true,
              allPermissions: permissions,
              requiredPermissions: 'nova_auth.user_management.list',
            }}
            data-grid={{ x: 0, y: 0, w: 4, h: 3 }}
          />
          <Widget
            bgColor="SSB050"
            name={formatMessage({
              id: 'dashboard.widget.name.create_csv_campaign',
            })}
            icon={
              <UploadIcon height="54" width="54" className={classes.icon} />
            }
            deletable={false}
            onClick={handleClickToNewCampaign(
              CommunicationAudienceTypeEnum.CSV,
            )}
            key="create-csv-campaign"
            loading={false}
            isDraggable={draggable}
            permissions={{
              required: true,
              allPermissions: permissions,
              requiredPermissions: 'campaign_management.communications.create',
            }}
            data-grid={{ x: 0, y: 0, w: 4, h: 3 }}
          />
          <Widget
            bgColor="SY050"
            name={numberWithCommas(simulateReportsStats?.total_bets)}
            description={formatMessage({
              id: simulateReportsStats
                ? 'dashboard.widget.description.simulate.number_of_bets'
                : 'dashboard.widget.description.simulate.number_of_bets.unavailable',
            })}
            icon={
              <SimulateIcon height="54" width="54" customClass={classes.icon} />
            }
            key="simulate-reports-widget"
            data-grid={{ w: 4, h: 3, x: 12, y: 3 }}
            deletable={false}
            dataTest="simulate-reports-widget"
            loading={simulateReportsStatsLoading}
            isDraggable={true}
            permissions={{
              required: true,
              allPermissions: permissions,
              requiredPermissions: 'simulate_reports.general_stats.get',
            }}
            onClick={() => {}}
            isClickable={false}
          />
          <Widget
            bgColor="ST050"
            name={formatMessage({
              id: 'dashboard.widget.name.service_desk',
            })}
            description={formatMessage({
              id: 'dashboard.widget.description.service_desk',
            })}
            icon={
              <SupportIcon height="54" width="54" className={classes.icon} />
            }
            deletable={false}
            onClick={handleOpenLink(
              'https://logifuture-jira.atlassian.net/servicedesk/customer/portal/6',
              true,
              'service-desk',
            )}
            key="service-desk-widget"
            isDraggable={draggable}
            data-grid={{ x: 0, y: 0, w: 4, h: 3 }}
          />
          <Widget
            bgColor="SAG050"
            name={formatMessage({
              id: 'dashboard.widget.name.create_campaign_with_broadcast',
            })}
            icon={<BroadcastIcon className={classes.icon} />}
            deletable={false}
            onClick={handleClickToNewCampaign(
              CommunicationAudienceTypeEnum.BROADCAST,
            )}
            key="create-login-campaign"
            isDraggable={draggable}
            permissions={{
              required: true,
              allPermissions: permissions,
              requiredPermissions: 'campaign_management.communications.create',
            }}
            data-grid={{ x: 0, y: 0, w: 4, h: 3 }}
          />
          {data ? renderCustomLinks() : <></>}
        </ReactGridLayout>
      )}
      <Notification />
    </div>
  )
}

export default WidgetCollection
