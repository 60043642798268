import Head from 'next/head'
import Dashboard from '../Dashboard'

const App: React.FC = () => {
  return (
    <>
      <Head>
        <title>Home page</title>
      </Head>
      <Dashboard />
    </>
  )
}

export default App
